//variables

// dont use bourbon box-sizing mixin with suzy
$susy: ( use-custom: ( box-sizing: false ));

$gutter: (gutters: 0.1, gutter-position: inside, use-custom: (box-sizing: false));
$splitgutter: (gutters: 0.1, gutter-position: split, use-custom: (box-sizing: false));
$minigutter: (gutters: 0.03, gutter-position: before, use-custom: (box-sizing: false));
$smallgutter: (gutters: 0.07, gutter-position: before, use-custom: (box-sizing: false));
$nogutter: (gutters: 0, use-custom: (box-sizing: false));

$black: #161616;
$white: #FFF;
$lightbrown: #D7D3CE;
$brown: #C0BAB0;
$darkbrown: #ad9979;
$darkerbrown: #615c55;
$blue: #427EC7;
$pink: #E51B6E;
$red: #e81818;

$medium: 640px;
$shmedium: 900px;
$large: 1200px;
$xlarge: 1600px;
$navswitch: 1500px;

$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutBounce: cubic-bezier(0.680, 0, 0.265, 1);

// Other vars
$header-height: 100px;

// Vue generic state transitions:
$minicart-anim-time: 0.2s;
$cart-item-anim-time: 0.25s;
$cart-item-delay-time: 300ms;

// rkt CSS util stuff
@mixin rkt($args...) {
	-content: "rkt #{$args}";
	&:before {
		content: "rkt #{$args}";
		width: 0;
		height: 0;
		position: absolute;
		overflow: hidden; } }
