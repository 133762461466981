article.barba-container#store-locations {
	margin-top: 65px;
	padding-top: 0;
	position: relative;
	@include susy-breakpoint($medium) {
		margin-top: 82px; } }

.locations-wrap {
	position: relative;
	min-height: 100vh;
	width: 100%; }

.locations-map-container {
	background: grey;
	@include susy-breakpoint($medium) {
		top: 82px;
		position: fixed;
		right: 0;
		width: 50%;
		min-height: 100vh; } }

#locations-map {
	height: 500px;
	width: 100%;
	@include susy-breakpoint($medium) {
		height: auto;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		position: absolute; } }

.stores-wrap {
	padding: 1rem;

	@include susy-breakpoint($medium) {
		padding-top: calc(50px + 2rem); }

	.stores {
		@include susy-breakpoint($medium) {
			@include span(1 of 2); } }

	.store {
		margin-bottom: 2rem; } }
