.popular-tags {
	text-align: center;
	padding: 1rem;
	@screen sm {
		padding: 2rem; } }

.recipe-search {
	padding: 1rem;
	@screen sm {
		padding: 2rem; }
	form {
		@apply .flex .flex-col {}
		@screen sm {
			@apply .flex-row {}
			input[type="text"] {
				width: 100%; } }

		button {
			@apply .mt-05 {}
			@screen sm {
				@apply .mt-0 .ml-05 {} } } } }

.recipes-wrap {
	// padding: 2rem 0

	.recipes {
		@include container(100%);
		display: flex;
		flex-wrap: wrap; }

	.recipe-container {
		@apply .flex {}
		@include susy-breakpoint($shmedium) {
			width: 50%; }
		@include susy-breakpoint($large) {
			width: 33.3333%; } }

	.recipe {
		position: relative;
		display: flex;
		flex: 1;
		flex-direction: column;
		margin: 1rem;
		transition: all 0.3s ease;
		box-shadow: 0 0 0 2px $lightbrown;

		&:hover {
			box-shadow: 0 0 0 2px $lightbrown, 0 0 15px 0px rgba($black, 0.15);

			.title {
				color: $black; }

			.price {
				color: $black; } } }


	.image {
		background: $lightbrown;
		min-height: 200px; }

	.featured-label {
		font-family: 'Praktika-BoldExtended';
		text-transform: uppercase;
		font-size: 0.7rem;
		background: $pink;
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		color: white;
		padding: 0.25rem 0.5rem;
		text-align: center; }

	.badges {
		position: absolute;
		top: 1rem;
		right: 1rem; }
	.badge {
		width: 70px;
		height: 70px;
		background: rgba($lightbrown, 0.8);
		color: $black;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		border-radius: 50%;
		line-height: normal;
		font-weight: 800;
		font-style: italic;
		margin-bottom: 0.5rem;

		span.badge-title {
			font-size: 0.9rem; }

		span.badge-number {
			font-size: 1.1rem;
			margin-left: -0.05rem;
			letter-spacing: -0.05rem; } }

	.title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		text-align: center;
		padding: 1rem;
		line-height: 1.1;
		transition: all 0.3s ease;
		font-weight: 800;
		font-style: italic; }

	.sub-title {
		display: block;
		font-weight: 400;
		font-size: smaller;
		padding-top: 0.35rem;
		color: #96928b; }

	.recipe-info {
		font-style: italic;
		opacity: 0.5;
		font-size: 1rem;
		padding-top: 0.5rem; }

	.price {
		text-align: center;
		padding: 0 2rem 2rem;
		font-size: 1.5rem;
		transition: all 0.3s ease; }

	.no-recipes {
		text-align: center;
		padding-top: 10vh; }

	.recipes-header {
		@apply .justify-between {}
		border: 2px solid $lightbrown;
		border-left: none;
		border-right: none;
		margin: 0 1rem 1rem;
		padding: 0.5rem 0;
		display: flex;

		i {
			font-size: 0.8rem;
			padding-right: 0.2rem; }

		a {
			padding-left: 0.75rem;

			&.active {
				text-decoration: underline; } } }

	.pagination {
		display: flex;
		width: calc(100% - 2rem);
		justify-content: space-between;
		border: 2px solid $lightbrown;
		border-left: none;
		border-right: none;
		margin: 1rem; } }

#recipe {
	@apply .px-1 {}

	.section-title {
		margin-left: auto;
		margin-right: auto;
		max-width: 800px; }

	.container {

		.recipe-hero-image {
			margin-top: 2rem;
			width: 100%;
			// min-height: 600px
 }			// background-size: cover

		.recipe-body {
			@apply .py-1 {}
			@screen sm {
				@apply .py-2 .flex .justify-between {} }

			.ingredients {
				@screen sm {
					flex: 0 0 30%; }

				.ingredients__list {
					list-style-type: disc;
					padding-left: 2rem;
					li {
						margin: 1rem 0;
 } } }						// padding-left: 0.5rem

			.method {
				@screen sm {
					flex: 0 0 60%; }

				.method__steps {
					list-style-type: decimal;
					padding-left: 2rem;
					li {
						margin: 1rem 0;
 } } }						// padding-left: 1rem

			.ingredients__title,
			.method__title {
				font-size: 0.8rem;
				padding: 1rem 0;
				border-bottom: 2px solid $lightbrown; }

			.method__title {
				display: flex;
				justify-content: space-between;
				h4 {
					margin: 0; } } }


		.recipe-header {
			border: 2px solid $lightbrown;
			border-left: none;
			border-right: none;
			margin: 1rem 0;
			padding: 0.5rem 0;
			@screen sm {
				@apply .flex .justify-between {} }

			i {
				font-size: 0.8rem;
				padding-right: 0.2rem; }

			a {
				padding-left: 0.75rem;

				&.active {
					text-decoration: underline; } } }

		.products-used,
		.tags,
		.share-recipe {
			margin: 1rem 0;
			border: 2px solid $lightbrown;
			padding: 1rem;
			@screen sm {
				padding: 2rem;
				margin: 4rem 0; }

			h3.section-title {
				margin-top: 0; } }


		.tags {
			text-align: center; }



		.border {
 } } }			// border-bottom: 1px solid $lightbrown
