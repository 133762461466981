.category-wrap {
	@apply .flex-wrap .flex .self-start {}
	padding: 1rem 0;
	margin-left: auto;
	@include susy-breakpoint($large) {
		padding: 2rem 0;
		&.has-sidebar {
			width: calc(100% - 300px); } } }


.products {
	@include container(100%);
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	&.centered {
		justify-content: center; } }

.category-outer,
.product-outer {
	@apply .flex {}
	@include susy-breakpoint($medium) {
		width: 50%; }
	@include susy-breakpoint($large) {
		width: 33.3333%; }

	&.four-across {
		@include susy-breakpoint($medium) {
			width: 50%; }
		@include susy-breakpoint($large) {
			width: 25%; } }

	.category,
	.product {
		display: flex;
		flex-direction: column;
		margin: 1rem;
		// border: 1px solid $lightbrown
		box-shadow: 0 0 0 2px $lightbrown;
		margin-bottom: 1rem;
		transition: all 0.3s ease;
		position: relative;

		&:hover {
			box-shadow: 0 0 0 2px $lightbrown, 0 0 15px 0px rgba($black, 0.15);

			.title {
				color: $black; }

			.price {
				color: $black; }

			.hero-image {
				opacity: 1; } } }

	.hero-image {
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
		right: 0;
		transition: all 0.3s ease;
		box-shadow: 0 1px 0 0px $lightbrown; }

	.image {
		background: $lightbrown;
		min-height: 200px; }

	.category-title {
		text-align: center;
		padding: 1rem;
		transition: all 0.3s ease;
		@include susy-breakpoint($medium) {
			padding: 2rem; } }

	.title {
		text-align: center;
		padding: 1rem 1rem 0;
		transition: all 0.3s ease;
		line-height: 1.2;
		@include susy-breakpoint($medium) {
			padding: 2rem 2rem 0; } }

	.price {
		text-align: center;
		padding: 0 1rem 1rem;
		font-size: 1.5rem;
		transition: all 0.3s ease;
		@include susy-breakpoint($medium) {
			padding: 0 2rem 2rem; } }

	.no-products {
		text-align: center;
		padding-top: 10vh; } }

.category-header {
	@apply .justify-between {}
	border: 2px solid $lightbrown;
	border-left: none;
	border-right: none;
	margin: 0 1rem 1rem;
	padding: 0.5rem 0;
	width: 100%;
	display: flex;

	i {
		font-size: 0.8rem;
		padding-right: 0.2rem; }

	a {
		padding-left: 0.75rem;

		&.active {
			text-decoration: underline; } } }

.pagination {
	display: flex;
	width: calc(100% - 2rem);
	justify-content: space-between;
	border: 2px solid $lightbrown;
	padding: 0.5rem 0;
	border-left: none;
	border-right: none;
	margin: 1rem; }
