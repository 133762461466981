ul.nav {
	@apply .fixed .bg-black {}
	top: 65px;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
	padding: 1rem;
	transform: translateX(-100%);
	transition: transform $minicart-anim-time $easeInOutQuart;

	&.open {
		a.inactive {
			@apply opacity-50 {} } }

	&.active {
		transform: translateX(0); }

	@include susy-breakpoint($navswitch) {
		background: transparent;
		@apply .static {}
		padding: 0;
		top: 82px;
		transform: translateX(0); }

	& > li {
		@include susy-breakpoint($navswitch) {
			margin: 0; } }


	li {
		@include susy-breakpoint($navswitch) {
			display: inline-block;
			margin: 0 1rem;
			border-bottom: 2px solid transparent;

			&.active {
				border-bottom: 2px solid $white; } }

		&.top-level {
			@apply .font-praktika .uppercase {} } }

	a {
		font-size: 1.3rem;
		@include susy-breakpoint($navswitch) {
			letter-spacing: 0.1rem;
			font-size: .9rem; } }

	ul.sub {
		@apply mb-2 {}
		@include susy-breakpoint($navswitch) {
			display: none; }

		a {
			font-size: 1.2rem;
			margin-right: 1rem; }

		ul.sub {
			display: none; } }

	li.child-active {
		ul.sub {
			li.active {
				ul.sub {
					display: block;
					margin-left: 1rem; } } } }

	li.social {
		@include susy-breakpoint($navswitch) {
			display: none; } } }


.page-nav {
	@apply .bg-brown .font-praktika .uppercase .fixed .flex .justify-center {}
	width: 100%;
	top: 65px;
	left: 0;
	right: 0;
	height: 50px;
	z-index: 2;
	display: none;
	@include susy-breakpoint($navswitch) {
		@apply .flex {}
		top: 82px; }

	&.invert {
		@apply .bg-white {} }

	ul {
		@apply .flex .items-center .justify-center {} }

	li {
		padding: 0.5rem 1rem;
		&.active a {
			@apply .border-b-2 .border-black {} } }

	a {
		@apply .flex {}
		font-size: .6rem;
		@include susy-breakpoint($navswitch) {
			font-size: .8rem; } }

	span {
		@apply .inline-block {}
		line-height: 1;
		text-align: center; } }
