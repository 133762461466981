.slick-dots {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	text-align: center;
	li {
		display: inline-block;
		margin: 1rem;

		&.slick-active {
			button {
				opacity: 1; } } }

	button {
		opacity: 0.5;
		font-size: 0;
		background: white;
		width: 12px;
		height: 12px;
		border: 2px solid $lightbrown;
		border-radius: 50%; } }
