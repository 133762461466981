article.barba-container:not(#home) {
	position: relative;
	margin-bottom: 2rem;
	min-height: 100vh;
	padding-top: calc(65px + 1rem);
	@include susy-breakpoint($large) {
		padding-top: calc(82px + 50px + 2rem);
		margin-bottom: 4rem; } }

article.barba-container#account,
article.barba-container#contact {
	padding-top: calc(65px + 2rem);
	@include susy-breakpoint($large) {
		padding-top: calc(82px + 3rem); } }

article.barba-container#home {
	padding-top: calc(65px);
	@include susy-breakpoint($large) {
		padding-top: calc(82px); } }

.illustration-header {
	margin-bottom: 2rem;
	@include susy-breakpoint($medium) {
		margin-bottom: 3rem; } }

.page-wrapper {
	padding-top: 50px; }

a {
	color: inherit;
	text-decoration: none; }

a.inline {
	color: $pink;
	text-decoration: underline; }

p.center {
	text-align: center; }

hr {
	border: none;
	border-top: 2px solid $lightbrown;
	margin: 2rem 0; }

.container {
	@include container(100%);
	&.c400 {
		@include container(400px); }
	&.c800 {
		@include container(800px); }
	&.c1200 {
		@include container(1200px); }
	&.c1600 {
		@include container(1600px); }
	&.cpad {
		padding: 1rem;
		@include susy-breakpoint($medium) {
			padding: 2rem; } }

	&.has-sidebar {
		display: flex; }

	@apply .px-1 {
		@screen sm {
			@apply .px-2 {} } } }


.barba-container#default {
	h1, h2, h3 {
		text-align: center; } }

.copy {
	h2, h3 {
		margin-bottom: 1rem; }
	p {
		line-height: 1.3;
		font-size: 105%;
		margin-bottom: 1rem; }

	ul {
		@apply ml-2 mb-1 {}
		li {
			@apply list-disc {} } } }

.sidebar {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 300px;
	width: 300px;
	padding: 2rem 1rem;
	display: none;
	@include susy-breakpoint($large) {
		display: block;
		position: sticky;
		top: 3rem; }

	&.fixed {
		position: fixed;
		margin-top: 1.5rem; }

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		&.sub {
 } }			// padding-left: 0.5rem

	li {
		&.top-level {
			margin-bottom: 1rem; } }

	a {
		display: block;
		color: $black;
		&.top-level {
			font-family: 'Praktika-BoldExtended';
			text-transform: uppercase;
			color: $black;
			font-size: 0.9rem;
			padding: 1.2rem 0 0.5rem;
			border-top: 2px solid $lightbrown; }

		&.sub-level {
			padding: 0.2rem 0; }

		&.active,
		&:hover {
			color: $black;
			font-weight: bold; } } }


.banner-snout,
.arrow {
	width: 4rem;
	margin: 0 auto; }

.arrow {
	rect,
	polygon {
		fill: $black;
		transition: all 0.3s; } }

.hover-block {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		box-shadow: inset 0 0 0 rgba($brown, 1);
		transition: all 0.15s $easeInOutQuad;
		z-index: 2; }

	&:hover {
		&::after {
			box-shadow: inset 0 0 0 5px rgba($brown, 1); }

		.hover-content {
			opacity: 1; }

		.arrow {
			animation: arrow 0.5s ease alternate;
			rect,
			polygon {
				fill: $blue; } } }

	.hover-content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: $blue;
		opacity: 0;
		z-index: 1;
		transition: all 0.25s ease;
		padding: 1rem;
		text-align: center;
		color: $white;
		text-transform: uppercase; } }

@keyframes arrow {
	0% {
		opacity: 0;
		transform: translateX(-50px); }
	100% {
		opacity: 1;
		transform: translateX(0); } }

.product-carousel-wrap {
	border: 1px solid $lightbrown;
	position: relative;
	text-align: center;

	.slick-track {
		display: flex;

		.slick-slide {
			display: flex;
			flex-direction: column;
			height: auto; }

		.hover-content {
			margin-top: auto; } }

	.slick-prev {
		position: absolute;
		left: -2rem;
		width: 1.2rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 3rem;
		cursor: pointer;
		@include susy-breakpoint($medium) {
			left: -2.5rem; } }

	.slick-next {
		position: absolute;
		right: -2rem;
		width: 1.2rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 3rem;
		cursor: pointer;
		@include susy-breakpoint($medium) {
			right: -2.5rem; } }

	.carousel-item {
		position: relative;
		text-decoration: none;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			box-shadow: inset 0 0 0 1px $lightbrown; } }

	.product-info {
		padding: 1rem;

		.title {
			text-transform: uppercase;
			padding-bottom: 1rem;
			margin-bottom: 0; }

		.desc {
			font-style: italic; } }

	.hover-content {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: calc(1rem + 5px);

		.arrow {
			rect,
			polygon {
				fill: $white; } } } }

.quantity-wrap {
	float: right;
	padding-left: 1rem;
	@include susy-breakpoint($medium) {
		padding-left: 2rem;
		float: left; } }


.qty-adjuster {
	display: flex;
	position: relative; }

.connect {
	text-align: center;
	padding: 2rem;
	@include susy-breakpoint($medium) {
		padding: 4rem; }

	.container {
		border: 2px solid $lightbrown; }

	h4 {
		margin-top: 0; }

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none; }
	li {
		display: inline-block; }

	a.social-link {
		padding: 0.25rem 1rem;
		font-size: 2rem; }

	.result-message.show:not(.success) {
		@apply pt-1 {}
		@screen md {
			@apply pt-2 {} }

		a {
			@apply block underline {} } } }



// VUE transition styles //

// "flip" up/down style:
.flip-updown-enter-active,
.flip-updown-leave-active {
	transition: all .1s ease; }
.flip-updown-enter {
	transform: translateY(-8px);
	opacity: 0; }
.flip-updown-leave-to {
	transform: translateY(8px);
	opacity: 0; }

// "hot flash" style:
.hotflash-enter-active {
	transition: all 3s ease-out; }//cubic-bezier(0,.55,.18,1)
.hotflash-leave-active {
 }	//transition: all 0.3s ease-in//cubic-bezier(0,.55,.18,1)
.hotflash-enter {
	color: $blue;
	opacity: 1; }
.hotflash-enter-to {
	color: $black; }
.hotflash-leave {
 }	//start style
.hotflash-leave-to {
	opacity: 0; }

#nav-overlay,
#cart-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, 0.7);
	z-index: 7;
	cursor: pointer;
	opacity: 0;
	transition: all 0.3s ease;
	pointer-events: none;

	&.open,
	&.active {
		visibility: visible;
		opacity: 1;
		pointer-events: all; } }

#nav-overlay {
	z-index: 2; }

.breadcrumbs {
	margin: 0;
	padding: 0;
	list-style-type: none;
	color: $black;
	text-align: center;
	font-style: italic;

	li {
		display: inline-block; }

	a:hover {
		text-decoration: underline; }

	.spacer {
		padding: 0 .7rem; } }


.tag {
	background: $lightbrown;
	cursor: pointer;
	padding: 0.25rem 0.7rem;
	display: inline-block;
	color: black;
	// margin-bottom: 0.18rem
	// font-size: 0.75rem
	font-size: 0.9rem;
	font-style: italic;
	font-weight: 800;
	vertical-align: middle;
	margin: 0.2rem 0.2rem 0.2rem 0;
	border-radius: 50px;

	&:hover {
		background: $black;
		color: $white; } }

ul.share-mediums {
	text-align: center;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		list-style-type: none;
		margin: 0 0.5rem; } }

#contact-form {
	.thankyou,
	.honeypot {
		display: none; } }

iframe.gift-vouchers {
	width: 100%;
	min-height: 100vh; }


.our-brands {
	text-align: center;
	@apply .flex .items-center .flex-wrap .justify-center {}
	@screen mobile {
		@apply .flex-col {} }

	.brand {
		@apply .px-05 {}
		width: 33.3333%;
		@screen mobile {
			max-width: 300px;
			width: 100%; } } }


// shop popup modal

.featherlight {
	&:last-of-type {
		background: rgba($black, 0.3); }

	.featherlight-close-icon {
		display: none; }

	.featherlight-content {
		background: $black;
		color: $white;
		border: 5px solid $white;
		box-shadow: 5px 5px 0 0 rgba($black, 0.2);
		border-radius: 5px;
		text-align: center;
		padding: 2rem 3rem;
		margin-bottom: 5%;

		h2 {
			margin-bottom: 1rem; }

		.btn {
			margin-top: 1rem; } } }

.copy {
	a:not(.btn) {
		@apply .underline {} } }

.online-specials-banner {
	display: block;
	margin: 2rem;
	box-shadow: 0 0 0 2px $lightbrown;
	margin-bottom: 1rem;
	transition: all 0.3s ease;
	position: relative;
	@screen md {
		margin-bottom: 4rem; }

	&:hover {
		box-shadow: 0 0 0 2px $lightbrown, 0 0 15px 0px rgba($black, 0.15); } }


.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; }

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }


.alert {
	&.info {
		background: $blue;
		color: $white;
		@apply .p-1 .text-left .mb-2 {}
		@screen md {
			@apply .p-2 {} } } }
