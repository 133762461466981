header .right .cart-trigger {
	cursor: pointer;
	span {
		position: absolute;
		top: 0;
		right: -.7rem;
		width: 1.2rem;
		height: 1.2rem;
		line-height: 1.3rem;
		background: $white;
		color: $black;
		border-radius: 50%;
		text-align: center;
		font-size: 0.8rem;
		font-family: 'Praktika-BoldExtended'; } }

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s; }

.fade-enter, .fade-leave-to {
	opacity: 0; }

// MAIN/TOP LEVEL CART ELEMENT
#cart {
	//used to only apply the min-height attribute on the 'basket' page - see basket.pug for declaration
	&.basket {
		min-height: 550px;
		transition: all 0.2s ease; }

	&:not(.mini) {
		// padding-top: 2rem

		&.empty {
			padding: 10rem 0; }

		a.btn {
			margin-top: 1rem; } }

	.cart-wrap {
		font-family: 'Praktika-BoldExtended';
		width: 100%;
		background: $white;
		padding: 0 1rem; }

	//overrides for mini cart:
	&.mini {
		.cart-wrap {
			@include container(450px);
			position: fixed;
			z-index: 8;
			right: 0;
			top: 0;
			bottom: 0;
			overflow-y: auto;
			// box-shadow: -10px 0 5px 0 rgba($black, 0.1)
			padding: 65px 1.5rem 1.5rem;
			font-size: 0.9rem;
			transition: all $minicart-anim-time $easeInOutQuart;
			transform: translateX(100%);

			@include susy-breakpoint($large) {
				padding: 82px 1.5rem 1.5rem;
 }				// border-left: 2px solid $lightbrown

			h2 {
				position: absolute;
				top: 1.5rem;
				left: 1.5rem;
				@include susy-breakpoint($large) {
					top: 1.65rem; } }

			.cart-close {
				position: absolute;
				top: 1.2rem;
				right: 1.5rem;
				font-size: 1.7rem;
				cursor: pointer;
				@include susy-breakpoint($large) {
					top: 1.5rem; } }

			&.open {
				transform: translateX(0); } } } }


.cart {
	font-family: 'Praktika-BoldExtended';
	text-transform: uppercase;

	&:not(.mini) {
		.cart-items {
			margin-bottom: 1rem; } }

	.empty {
		text-align: center; }

	/* CART ITEMS */
	.cart-items {
		border-top: 2px solid $lightbrown;

		.cart-row {
			border-bottom: 2px solid $lightbrown;
			padding: 1rem 0;

			&.light-row {
				border-bottom: 2px solid rgba($lightbrown, 0.5); }

			&.subtotal {
				border-bottom: none; }
			&.total {
				.total-label {
					color: $black; } } }

		.cart-item.cart-row {
			padding: 0.5rem 0;
			display: flex;
			transition: all $cart-item-anim-time;
			transition-delay: $cart-item-delay-time;

			&:hover {
				.product-remove {
					opacity: 1; } } }

		/* Vue transition Classes: */
		.cart-item-enter,
		.cart-item-leave-to {
			opacity: 0;
			transform: translateY(-30px); }

		.cart-item-leave-active {
			//position: absolute
			transition-delay: 0ms;
			transform: translateY(30px); } }


	&:not(.mini) {
		.cart-item.cart-row {
			@include susy-breakpoint($medium) {
				padding: 0; } } }

	.col,
	.sub-col {
		// align-items: center
		display: flex;
		@include susy-breakpoint($medium) {
			padding: 0.5rem; } }

	&:not(.mini) {
		.col,
		.sub-col {
			@include susy-breakpoint($medium) {
				padding: 1rem; } } }

	.product-image {
		max-width: 100px;
		position: relative;
		padding: 0.5rem;
		padding-left: 0;
		align-self: start;
		flex-shrink: 0;
		display: none;
		@include susy-breakpoint($medium) {
			display: block; } }

	&:not(.mini) {
		.product-image {
			@include susy-breakpoint($medium) {
				max-width: 200px;
				padding: 1rem 1rem 1rem 0; } } }

	.product-info {
		flex-grow: 1;
		flex-direction: column;
		padding: 0;
		position: relative;
		padding: 0.5rem 0;
		justify-content: space-between; }

	.product-purchase-data {
		display: flex;
		justify-content: space-between; }

	&:not(.mini) {
		.product-info {
			@include susy-breakpoint($medium) {
				padding: 1rem 0rem;
				flex-direction: row; } } }

	.product-title {
		flex-direction: column;
		align-items: normal;
		align-self: start;
		padding-top: 0;
		padding-bottom: 0.5rem;
		@include susy-breakpoint($medium) {
			padding-bottom: 0; } }

	&:not(.mini) {
		.product-title {
			@include susy-breakpoint($medium) {
				align-self: center; } } }

	.product-detail {
		color: $brown;
		text-transform: none;
		@include susy-breakpoint($medium) {
			margin-top: 0.3rem; } }

	.product-total {
		align-self: center; }

	&:not(.mini) {
		.product-detail {
			@include susy-breakpoint($medium) {
				margin-top: 0.5rem; } } }

	// .product-qty
	// 	position: absolute
	// 	left: 0
	// 	bottom: 0

	&.mini {
		.product-qty {
			button {
				padding: 0;
				min-width: 2rem; } } }

	&:not(.mini) {
		.product-qty {
			@include susy-breakpoint($medium) {
				position: relative;
				align-self: center;
				margin-left: auto; } } }

	.product-remove {
		transition: all 0.3s ease;
		opacity: 0;
		position: absolute;
		left: 0;
		bottom: -1.5rem;
		width: 100%;
		text-align: center;
		font-size: 0.8rem;
		color: $brown;
		cursor: pointer;

		&:hover {
			color: $black; }

		&.mini {
			left: auto;
			top: 0;
			right: 0;
			bottom: auto;
			width: auto;
			font-size: 1.25rem; } }

	// .product-total
	// 	position: absolute
	// 	bottom: 0
	// 	right: 0
	// 	padding: 0.7rem 0

	&:not(.mini) {
		.product-total {
			@include susy-breakpoint($medium) {
				padding: 1rem 0;
				position: static;
				min-width: 7rem;
				align-items: flex-end;
				align-self: center;
				flex-direction: column; } } }

	&:not(.mini) {
		.coupon {
			input {
				@include susy-breakpoint($medium) {
					@include span(1 of 2 first $minigutter); } }

			button {
				@include susy-breakpoint($medium) {
					@include span(1 of 2 last $minigutter); } } } }

	.cart-total {
		padding: 2rem 0; }

	.cart-totals-wrap {
		padding: 1rem 0; }

	.cart-totals {
		text-align: right;
		display: flex;
		padding: .5rem 0 .4rem;

		.total-label {
			margin-left: 0;
			// font-size: 0.9rem
			// letter-spacing: 0.2rem
			text-transform: none;
			color: $brown;

			a.discount-kill {
				background: $brown;
				cursor: pointer;
				padding: 0.2rem 0.7rem;
				display: inline-block;
				color: black;
				margin-bottom: 0.18rem;
				font-size: 0.75rem;
				letter-spacing: 0.15rem;
				text-transform: uppercase;
				vertical-align: middle;
				&:last-child {
					margin-bottom: 0; }
				&:hover {
					background-color: $blue;
					color: $white; } } } }

	&:not(.mini) {
		.cart-totals {
			.total-label {
				@include susy-breakpoint($medium) {
					margin-left: auto; } } } }

	.total-value {
		min-width: 10rem;
		text-transform: none;
		margin-left: auto; }

	&:not(.mini) {
		.total-value {
			@include susy-breakpoint($medium) {
				margin-left: 0; } } }

	.cart-actions {
		border-bottom: none;

		a:first-child {
			@include susy-breakpoint($medium) {
				@include span(1 of 2 first $minigutter); } }

		a:last-child {
			@include susy-breakpoint($medium) {
				@include span(1 of 2 last $minigutter); } } }

	.cart-links {
		margin-top: 1rem; } }
