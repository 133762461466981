header {
	@apply .bg-black .text-white .p-1 .fixed .flex .items-center {}
	z-index: 3;
	width: 100%;
	top: 0;
	height: 65px;
	fill: $white;

	&.invert {
		@apply .bg-white .text-black {}
		fill: $black;
		border-bottom: 2px solid $lightbrown;

		ul.nav {
			@apply .bg-white {} }

		ul.nav li.active {
			border-color: $black; }

		.right .cart-trigger span {
			background: $black;
			color: $white; } }

	@include susy-breakpoint($navswitch) {
		height: 82px; }

	.header-elements {
		@apply .relative {}
		width: 100%; }

	.snout {
		width: 3rem;
		display: block;
		padding: 0.1rem;
		@apply .absolute {}
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include susy-breakpoint($navswitch) {
			@apply .static {}
			transform: none; }

		svg {
			fill: inherit; } }

	i {
		font-size: 1.7rem; }

	.nav-trigger {
		@include susy-breakpoint($navswitch) {
			display: none; } }

	.left {
		@apply .flex .items-center {}
		@include susy-breakpoint($navswitch) {
			flex-basis: 10%; } }

	.mid {
		@apply .flex .items-center {} }

	.right {
		@apply .flex .items-center {}
		padding-right: 0.5rem;
		@include susy-breakpoint($navswitch) {
			padding-top: 0.2rem;
			flex-basis: 10%; }

		.account-trigger {
			@apply .flex .items-center {}
			margin-left: auto;

			span {
				@apply .font-praktika .uppercase {}
				font-size: 0.6rem;
				padding-left: 0.5rem; } }

		#cart-counter {
			margin-left: 1rem;

			.cart-trigger {
				position: relative;
				display: flex; } } } }

.event-banner,
.shop-banner {
	position: fixed;
	z-index: 20;
	width: 100%;
	display: block;
	background: #921f1f;
	color: $white;
	text-align: center;
	padding: 0.5rem;
	bottom: 0;
	// top: 65px

	// +susy-breakpoint($navswitch)
 }	// 	top: 82px


.drop-nav-wrapper {
	@apply absolute left-0 right-0 bg-white hidden {}
	z-index: 2;
	top: 100%;
	@include susy-breakpoint($navswitch) {
		@apply block opacity-0 transition-all duration-500 {}

		&.open {
			@apply opacity-100 {} } }

	.drop-nav {
		@apply p-4 {}
		&.has-illustration {
			@apply grid gap-x-2 grid grid-cols-3 {}
			.drop-nav-links {
				@apply grid gap-x-2 col-span-2 grid-cols-2 {} } }
		&:not(.has-illustration) {
			.drop-nav-links {
				@apply grid grid-cols-3 justify-center {} } } }

	.drop-nav-links {
		@apply text-black content-start {}

		.drop-nav-link {
			@apply flex p-1 rounded-md {}
			&:hover {
				background: rgba(0,0,0,0.05); }

			.drop-nav-link-image {
				@apply w-auto {} }

			.drop-nav-link-text {
				@apply flex flex-col {} }

			.drop-nav-link-title {
				@apply font-mrsEaves font-bold italic text-2xl {} }

			.drop-nav-link-descriptor {
				@apply text-sm {} } } } }
