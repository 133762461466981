article.barba-container#rewards {
	padding-top: calc(65px + 2rem);
	@include susy-breakpoint($large) {
		padding-top: calc(82px + 50px + 2rem); }
	.copy {
		& > * {
			font-family: 'bebas-neue-pro-semiexpanded'; }

		a.btn {
			font-family: 'bebas-neue-pro-semiexpanded'; }

		h1 {
			font-weight: normal;
			letter-spacing: 0.2rem;
			font-size: 1.6rem;
			@include susy-breakpoint($large) {
				font-size: 2rem; } }

		h1, h2, h3 {
			text-transform: uppercase;
			font-style: normal;
			font-size: 1.4rem;
			line-height: 1;
			@include susy-breakpoint($large) {
				font-size: 1.8rem; }

			i {
				text-transform: none; } }

		h3 {
			font-size: 1.2rem;
			@include susy-breakpoint($large) {
				font-size: 1.4rem; } }

		img {
			max-width: 100%;
			width: auto; }

		.hero-image {
			@apply py-2 {} }

		h2, h3, p, ul {
			margin-bottom: 1rem;
			@include susy-breakpoint($large) {
				margin-bottom: 1rem; } }

		ul {
			li {
				margin-left: 1rem;
				list-style-type: disc; } }

		.rewards-buttons {
			a.btn {
				@apply my-1 {} }
			@include susy-breakpoint($large) {
				@apply grid grid-cols-2 gap-2 {} } } }

	.reward-footer-hero {
		@apply my-2 {}
		background-repeat: repeat-x;
		background-size: contian;
		min-height: 300px;
		@include susy-breakpoint($large) {
			@apply my-4 {}
			min-height: 500px; } }

	.current-promotions {
		min-height: 25vh; } }
