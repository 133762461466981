.barba-container#account {

	.loyalty-details {
		// display: flex
		.my-points,
		.my-card, {
 }			// flex: 1 1 50%

		.my-card {} }

	.order-items-overflow {
		overflow-x: scroll; }

	.order-status-label {
		font-size: 2.5rem;
		text-align: center; }

	.track-my-order {
		margin: 2rem auto 4rem; }

	.order-status-wrap {
		margin-top: 3rem;
		padding: 2rem 0;
		@include container(100%); }

	.order-status-bar {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;

		.status-section {
			// font-family: 'Praktika-BoldExtended'
			// text-transform: uppercase
			position: relative;
			padding-top: 1rem;
			border-top: 2px solid rgba($black,0.25);
			color: $lightbrown;
			text-align: center;
			@include span(1 of 3 $nogutter);

			&.now {
				color: $black;
				&::after {
					background: $black; } }

			&.done {
				// border-top-color: $black

				&::after {
					border: 2px solid $black;
					background: $black; } }

			&.cancelled,
			&.refunded {
				color: $red;

				&::after {
					border: 2px solid $red; }

				&.done {
					// border-top-color: $red
					&::after {
						background: $red; } } }

			&::after {
				content: " ";
				position: absolute;
				top: -9px;
				left: 50%;
				transform: translateX(-50%);
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: white;
				border: 2px solid rgba($black,0.25);
				box-shadow: 0 0 0px 5px $white; } } }

	.order-cols {

		.order-left {
			@include span(1 of 1);
			margin-bottom: 1rem;
			@include susy-breakpoint($medium) {
				@include span(1 of 2 first); } }

		.order-right {
			@include span(1 of 1);
			@include susy-breakpoint($medium) {
				@include span(1 of 2 last); } } }


	/* Loyalty Card Styling: */
	.lc-wrap {
		display: flex;
		justify-content: center;
		// margin-bottom: 3rem

		width: 100%;
		// max-width: 450px
		max-height: 300px;
		height: 54vw;
		padding: 0 20px 20px;

		/* FRONT OF CARD */
		#cardfront {}

		/* BACK OF CARD */
		#cardback {
			position: relative;
			// .barcode_bg
 }			// 	fill: #ffffff

		.barcode {
			position: relative;
			display: flex;
			flex-flow: column wrap;
			justify-content: flex-start;
			align-items: center;
			top: 27%;
			width: 75%;
			margin: 0 auto;
			min-height: 34%;
			padding: 0.4rem 0.75rem 0;
			background: #ffffff;
			> img {
				overflow: hidden;
				max-height: 60px; }

			.cust-no {
				width: auto;
				height: auto;
				font-family: monospace;
				font-size: 0.8rem;
				line-height: 1;
				letter-spacing: 0.6rem;
				text-align: center;
				@include susy-breakpoint($medium) {
					font-size: 1rem;
					margin-top: 0.3rem; } } }

		/* FLIP ANIMATION */
		.container {
			perspective: 1000px; }

		.loyaltycard {
			width: 100%;
			max-width: 450px;
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
			transition: -webkit-transform 0.6s;
			-webkit-transition: -webkit-transform 0.6s;
			transition: transform 0.6s;
			transition: transform 0.6s, -webkit-transform 0.6s;
			cursor: pointer; }

		.loyaltycard .front,
		.loyaltycard .back {
			position: absolute;
			width: 100%;
			height: 100%;
			max-width: 450px;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-font-smoothing: antialiased;
			color: #47525d;
			background-color: #000000;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			-webkit-box-shadow: 1px 5px 6px 0px rgba(0,0,0,0.2);
			box-shadow: 1px 5px 6px 0px rgba(0,0,0,0.2);
			border-radius: 22px; }

		.loyaltycard .front {
			background-image: url(../img/lc_front.png); }
		.loyaltycard .back {
			background-image: url(../img/lc_back.png);
			transform: rotateY(180deg); }

		.loyaltycard.flipped {
			transform: rotateY(180deg); } } }
