table {
	border-collapse: collapse;
	width: 100%;
	// border-top: 2px solid $lightbrown
	margin: 1rem 0;
	// min-width: 600px
	@include susy-breakpoint($medium) {
		margin: 2rem 0; }


	a {
		text-decoration: underline; }

	&.address-listing {
		min-width: 1000px; }

	td, th {
		text-align: left;
		padding: .75rem .5rem;
		border-bottom: 2px solid $lightbrown; }

	thead {
		th {
			border-bottom: 2px solid $brown; } }

	tfoot {
		td {
			border-bottom: 2px solid rgba($lightbrown, 0.5); }

		tr:last-child {
			td {
				border-bottom: none; } } }

	th:last-child,
	td:last-child {
		text-align: right; } }

#suburbs {
	table {
		th {
			@apply .sticky .bg-lightbrown {}
			&:last-child {
				text-align: center; } }

		h2, h3 {
			@apply .mb-0 {} } }

	@screen mini {
		th,
		td:not(:first-child) {
			display: none; } }

	.only-mobile {
		display: none;
		@screen mini {
			display: inline-block; } }

	div.only-mobile {
		@screen mini {
			@apply .flex .justify-between .content-center {} } } }
