//typography
h1, h2, h3, h4 {
	text-transform: uppercase;
	font-weight: normal;
	font-family: 'Praktika-BoldExtended';
	letter-spacing: 0.1rem; }

h1 {
	font-family: "mrs-eaves-xl-serif", serif;

	font-size: 2rem;
	font-weight: 800;
	font-style: italic;
	letter-spacing: 0;
	text-transform: none;
	@include susy-breakpoint($medium) {
		font-size: 2.5rem; } }

h3 {
	font-size: 1rem; }

h4 {
	font-size: 1rem; }

.section-title {
	text-align: center;
	margin-bottom: 1rem;
	line-height: 1;
	margin-top: 1rem;
	@include susy-breakpoint($medium) {
		margin-bottom: 2rem; } }

.push-right {
	float: right; }

.brown {
	color: $brown; }

.text-center {
	text-align: center; }

p.title {
	font-size: 2.5rem;
	font-weight: 800;
	font-style: italic;
	@include susy-breakpoint($medium) {
		font-size: 1.8rem; }
	@include susy-breakpoint($large) {
		font-size: 2.5rem; } }

p.sub-title {
	text-transform: uppercase;
	font-family: 'Praktika-BoldExtended';
	letter-spacing: 0.3rem; }

p.label {
	margin: 0.25rem 0;
	text-transform: uppercase;
	font-size: 0.9rem;
	font-family: 'Praktika-ExtraBoldExtended';
	letter-spacing: 0.1rem;
	@include susy-breakpoint($medium) {
		margin: 0.5rem 0; } }

.heading {
	margin: 0.5rem 0;
	text-transform: uppercase;
	// font-size: 0.9rem
	font-family: 'Praktika-BoldExtended';
	letter-spacing: 0.1rem; }
