//base html stuff
html,
body {
	background: white;
	height: 100%;
	min-height: 100%;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	color: $black;
	font-family: "mrs-eaves-xl-serif", serif;
	font-size: 100%;
	@include susy-breakpoint($medium) {
		font-size: 110%; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none !important; } }

body.loading {
	cursor: wait;
	header,
	#barba-wrapper,
	footer {
		pointer-events: none; } }

.material-icons {
	line-height: inherit; }

img {
	display: block;
	width: 100%; }

.clearfix:after {
	content: "";
	display: table;
	clear: both; }

#preload-bar {
	width: 100%;
	height: 2px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
	background: $black;

	svg {
		height: 2px; }

	path {
		stroke: #c0bab0;
		transition: all 0.5s ease; } }

#preload-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 500;
	background-color: $white;
	background-image: url(../img/snout-light.svg);
	background-size: 100px;
	background-position: center;
	background-repeat: no-repeat;
	transition: all 0.3s ease;

	&.hide {
		pointer-events: none;
		opacity: 0; }

	&.loaded {
		z-index: 2; } }

#navOverlay {
	@apply fixed inset-0 bg-black bg-opacity-50 cursor-pointer opacity-0 transition duration-500 pointer-events-none {}
	z-index: 2;
	&.active {
		@apply opacity-100 pointer-events-auto {} } }
