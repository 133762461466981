.checkout-block {
	margin: 1rem 0;
	@include susy-breakpoint($medium) {
		margin: 4rem 2rem; } }

.checkout-form {
	@include container(900px);
	padding: 0 1rem;
	@include susy-breakpoint($large) {
		@include span(1 of 2 $nogutter first); }

	.checkout-block {
		&.login-block {
			padding: 2rem;
			margin: 6rem auto 4rem;
			border: 2px solid $lightbrown;
			background: rgba($lightbrown, 0.2);
			text-align: center;

			h2.section-title {
				margin: 0; }

			p {
				margin-bottom: 1rem; } }

		&.billing-toggle {
			padding: 0 2rem;
			text-align: center;

			.show-billing-address {
				display: inline-block;
				max-width: none; }

			input[type="checkbox"] {
				display: none; } }

		&.payment-block {
			#stripe-form {
				padding: 1rem;
				border: 2px solid $lightbrown; } }

		&.checkout-finalise {
			text-align: center;
			a.btn {
				// font-size: 1.5rem
				display: inline-block;
				max-width: none;
				@include susy-breakpoint($medium) {
					width: auto;
 }					// font-size: 1.8rem

				&.processing {
					cursor: not-allowed;
					opacity: 0.25; } } } }

	.parsley-errors-list {
 } }		// display: none

.checkout-summary {
	@include container(900px);
	padding: 0 1rem;
	@include susy-breakpoint($large) {
		@include span(1 of 2 $nogutter last);
		position: sticky;
		top: 3rem; }

	&.sticky {
		position: fixed; }

	.order-coupons {
		font-weight: normal; }

	.totals-label {
		color: $darkbrown; } }
