#login {

	.login-box {
		margin-top: 2rem;
		@include susy-breakpoint($medium) {
			margin-top: 4rem;
			@include span(1 of 2 $smallgutter first); }
		@include susy-breakpoint($large) {
			margin: 4rem 0;
			@include span(1 of 2 first); } }

	.register-box {
		margin-top: 2rem;
		@include susy-breakpoint($medium) {
			margin-top: 4rem;
			@include span(1 of 2 $smallgutter last); }
		@include susy-breakpoint($large) {
			margin: 4rem 0;
			@include span(1 of 2 last); } } }
