#product {

	.product-flex {
		@include susy-breakpoint($shmedium) {
			display: flex; } }

	.product-left {
		@include span(1 of 1);
		padding: 2rem 0 1rem;
		@include susy-breakpoint($shmedium) {
			padding: 2rem 1rem 1rem;
			@include span(1 of 2 $nogutter first);

			text-align: center; }

		.product-image {
			width: auto;
			height: auto;
			width: 100%;
			max-width: 100%;
			margin: 0rem auto 1rem;
			@include susy-breakpoint($medium) {
				margin: 0rem auto 0; }

			@include susy-breakpoint($large) {
				max-width: 500px; }

			&.no-image {
				background: $lightbrown;
				padding-bottom: 100%;
				position: relative;

				&::after {
					content: "No Image";
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					text-align: center; } } } }

	.product-right {
		@include span(1 of 1);
		padding: 0 1rem;
		@include susy-breakpoint($shmedium) {
			padding: 2rem 1rem 1rem;
			display: flex;
			flex-direction: column;
			@include span(1 of 2 $nogutter last); }

		.product-title {
			margin: 0.5rem 0; }

		.product-description {
			margin-bottom: 1rem;
			@include susy-breakpoint($medium) {
				margin-bottom: 2rem; } }

		.product-detail-wrap {
			margin-bottom: 1.5rem; }

		.product-detail {
			margin: 0.5rem 0;

			a {
				text-decoration: underline; } }

		button.add-to-cart,
		a.add-to-cart {
			margin-top: 1rem;
			text-align: center;
			color: $white; }

		/* "Add to Cart" Component: */
		.product-purchasing {
			position: relative;
			margin-top: 1rem;
			padding-top: 1rem;
			border-top : 2px solid $lightbrown;
			@include susy-breakpoint($medium) {
				margin-top: auto; }

			&.out-of-stock {
				pointer-events: none;

				.out-of-stock-notice {
					position: absolute;
					top: 40%;
					width: 100%;
					text-align: center; }

				.product-purchasing-wrap {
					opacity: 0.1; } }



			p.label {
				margin-top: 0; }

			.price-wrap {
				float: left;
				width: 50%;
				padding-right: 2rem;
				margin-bottom: 1rem;
				border-right: 2px solid $lightbrown;

				.product-price {
					margin: -0.5rem 0 0;
					line-height: 40px;
					sup {
						line-height: 1;
						font-size: 0.75rem;
						top: 0rem;
						left: -0.5rem; } }

				.product-price__per-unit {
					margin: 0;
					opacity: 0.5; } } } }

	.related-products {
		padding: 2rem 3rem;
		@include susy-breakpoint($medium) {
			padding: 4rem; }
		h2 {
			text-align: center; } }

	.recipe-ideas {
		text-align: center;

		.recipe-ideas-wrapper {
			background: url('../img/marble_bg.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			@include container(100%);

			.recipe-ideas-left {
				@include span(1 of 1);
				@include susy-breakpoint($large) {
					@include span(1 of 2 $nogutter); }

				img {
					max-width: 700px;
					margin: 0 auto;
					@include susy-breakpoint($large) {
						max-width: none; } } }

			.recipe-ideas-right {
				display: flex;
				align-items: center;
				@include span(1 of 1);
				@include susy-breakpoint($large) {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					@include span(1 of 2 $nogutter); }

				.content {
					padding: 2rem;
					width: 100%;
					@include susy-breakpoint($large) {
						padding: 4rem; } } } } } }
