input.hidden {
	display: none; }

input {
	border-radius: 0;
	appearance: none;
	box-shadow: none; }

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
button:not([role="tab"]),
textarea,
select,
.btn {
	border: 2px solid $lightbrown;
	padding: 0.6rem 0.7rem;
	line-height: normal;
	display: block;
	min-width: 250px;
	text-align: left;
	// width: 100%

	&::placeholder {
		color: $brown; }

	&:-webkit-autofill {
		background: none; }

	&.full {
		width: 100%; }

	&[disabled] {
		border-color: rgba($lightbrown, 0.5); } }

button:not([role="tab"]),
a.btn {
	font-family: 'Praktika-BoldExtended';
	text-transform: uppercase;
	background: $black;
	border: 2px solid $black;
	font-size: 1rem;
	cursor: pointer;
	padding: .6rem 2rem 0.5rem;
	text-align: center;
	color: $white;
	@include susy-breakpoint($medium) {
		&:not(.full) {
			max-width: 360px; } }

	&.small {
		max-width: 200px; }

	&.center {
		margin: 0 auto;
		text-align: center; }

	&.alt {
		background: $lightbrown;
		border-color: $lightbrown;
		color: $black; }

	&.outline {
		background: none;
		border-color: $lightbrown;
		color: $black; }

	&.on-black {
		color: $lightbrown; }

	&.blue {
		background: $blue;
		border-color: $blue; }

	&.stacked {
		margin-bottom: 1rem; } }

select {
	appearance: none;
	border-radius: 0;
	background: none;
	width: 100%; }

textarea {
	min-height: 100px; }

.product-qty {
	button {
		font-family: 'Praktika-BoldExtended';
		padding: 0;
		text-align: center;
		letter-spacing: 0;
		// background: $lightbrown
		color: $black;
		background: transparent;
		border: 2px solid $lightbrown;
		min-height: 40px;
		width: 3rem;
		min-width: 0;
		@include susy-breakpoint($medium) {
			padding: 0.4rem 0.5rem 0.3rem; }

		&:hover {
			background: $lightbrown;
			border-color: $lightbrown; } }

	input {
		font-family: 'Praktika-BoldExtended';
		text-align: center;
		padding: 0 0.5rem;
		border-color: transparent;
		line-height: normal;
		border: 2px solid $lightbrown;
		border-right: none;
		border-left: none;
		min-height: 40px;
		width: 3rem;
		min-width: 0;
		@include susy-breakpoint($medium); } }

.field-row {
	margin-bottom: 1rem;
	@include container(100%);

	input,
	textarea {
		width: 100%; }

	&.half {
		div:first-of-type {
			margin-bottom: 1rem;
			@include susy-breakpoint($medium) {
				margin-bottom: 0;
				@include span(1 of 2 first $smallgutter); } }

		div:last-of-type {
			@include susy-breakpoint($medium) {
				@include span(1 of 2 last $smallgutter); } }

		&.split {
			div:first-of-type,
			.field-wrapper {
				@include span(1 of 1 first $smallgutter);
				margin-bottom: 1rem;
				@include susy-breakpoint(1400px) {
					margin-bottom: 0;
					@include span(1 of 2 first $smallgutter); } }

			.field-wrapper {
				position: relative;
				input:last-child,
				input:first-child {
					width: 100%;
					margin-bottom: 0; }

				i {
					position: absolute;
					top: 50%;
					right: 1rem;
					transform: translateY(-50%);
					color: $lightbrown; } }

			div:last-of-type {
				@include span(1 of 1 first $smallgutter);
				@include susy-breakpoint(1400px) {
					@include span(1 of 2 last $smallgutter); } } } } }


.errors {
	text-align: center;
	color: $red;

	ul {
		padding: 0;
		list-style-type: none;
		margin: 0.5rem 1rem;
		@include susy-breakpoint($medium) {
			margin: 1rem 2rem; } }

	li {
		padding: 0; } }


.parsley-error {
	border-color: $red !important; }

.parsley-errors-list {
	font-size: 0.9rem;
	color: red;
	padding: 0.25rem; }


.checkbox-wrapper {
	label {
		display: flex;
		padding: 0 !important; }

	.check-wrap {
		margin-left: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid; }

	.fa-check {
		opacity: 0;
		padding: 0 0.5rem; }
	label input {
		width: auto !important;
		visibility: hidden;
		position: absolute;

		&:checked ~ .check-wrap {
			.fa-check {
				opacity: 1; } } } }


.klaviyo-form {
	button {
		min-width: 0;
		background: transparent;
		padding: 0;
		border: none; } }
