article.barba-container#schulz {
	padding-top: calc(65px);
	@include susy-breakpoint($large) {
		padding-top: calc(82px + 50px); }

	.schulz-header {
		background-image: url(../img/schulz-header.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: top center;
		min-height: 4rem;
		@include susy-breakpoint($large) {
			min-height: 6rem; } }

	.schulz-logo {
		max-width: 200px;
		margin: 0 auto; }

	.category-outer .title,
	.product-outer .title {
		padding: 1rem 1rem 0; }

	.category-outer .price,
	.product-outer .price {
		padding: 0 1rem 1rem; } }
