#event-order {
	h1, h2, h3 {
		text-align: center; }

	.notification-message {
		text-align: center;
		color: $pink;
		font-weight: bold;
		margin-top: 2rem; }

	form {
		.event-fields {
			justify-content: flex-end;
			input,
			select {
				margin-left: 0.5rem; }
			input[type="text"],
			input[type="number"] {
				text-align: center;
				min-width: 0;
				width: 60px; }
			select {
				min-width: 0;
				width: 180px; }

			.parsley-errors-list {
				display: none; } }

		th {
			@apply .sticky .bg-darkbrown .text-black {}
			border-bottom: none; }

		th,
		td {
			padding: 0.75rem 1rem; }

		.table-heading th,
		.table-header {
			top: 65px;
			@screen navHeight {
				top: 82px; } }

		tr.options-header {
			td {
				border-bottom: none;
				padding-bottom: 0.15rem; } }
		tr.options-row {
			td {
				padding: 0.2rem 0.5rem;
				&:first-child {
					padding-left: 1rem; } }
			&:not(.last) {
				td {
					border-bottom: none; } }
			&.last {
				td {
					padding-bottom: 1rem; } } }

		.event-title-image {
			@apply .flex .flex-col {}
			@screen md {
				@apply .flex-row {} } }

		.event-product-image {
			width: 100%;
			max-width: 100px;
			margin-right: 1rem; }

		.hide-mobile {
			display: none;
			@screen md {
				display: table-cell; } }

		.mobile-only {
			display: block;
			@screen md {
				display: none; } }

		.event-product-desc {
			p {
				@apply leading-tight {}
				margin-bottom: 0; } }

		button[disabled] {
			@apply .bg-lightbrown .text-black .cursor-wait {} } }


	.product-image {
		max-width: 100vw;
		width: 100%;

		.slide {
			background-position: center;
			background-size: cover;
			position: relative;
			z-index: 1;
			display: none;

			&:first-child {
				display: block; } }

		&.slick-initialized {
			.slide {
				display: block; } } } }
