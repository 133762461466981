@media screen {
	.printbut {
		margin: 0 auto;
		width: 50%;
		text-align: center;
		font-size: 2rem; }

	#ordersReport {
		display: none; } }
@media print {
	header, nav, footer {
		display: none; } // just in case..

	.printbut {
		display: none; }

	#ordersReport {
		display: block; }
	@page {
		size: A4 portrait;
		margin: 1.5cm; }

	.order {
		page-break-before: always;
		max-height: 100%; }

	table {
		margin-top: 0;
		&.orderhead {
			tr {
				&::before {
					border: none; }
				&::after {
					border: none; } } }
		tr {
			td, th {
				font-size: 8pt;
				padding: .5rem; } } }

	h1 {
		font-size: 14pt;
		margin-bottom: 2rem; }
	h2 {
		font-size: 10pt; }
	h3 {
		font-size: 10pt; }
	h4 {
		font-size: 10pt; } }

