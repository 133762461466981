#notifications {
	font-size: 1rem;

	.toast {
		.toast-message {
			display: flex;
			position: relative;
			width: 100%;
			overflow: hidden;
			background-color: $white;
			transition: 400ms cubic-bezier(0.17, 0.67, 0.17, 0.98);
			transition-property: all;

			&.toast-type-info {
				background-color: $blue;
				color: $white; }
			&.toast-type-success {
				background-color: #39C16C;
				color: $white; }
			&.toast-type-warning {
				background-color: #ffae42;
				color: #7d4700; }
			&.toast-type-danger {
				background-color: $red;
				color: $white; } }

		&.toast-position-n {
			.toast-message {
				top: 0; } }

		&.toast-position-s {
			.toast-message {
				bottom: 0; } }

		.toast-message-text {
			height: 3rem;
			display: flex;
			text-align: center;
			align-items: center;
			font-family: 'Praktika-BoldExtended', sans-serif;
			@include container(100%);
			@include susy-breakpoint($large) {
				@include container(1200px); }
			strong {
				margin-right: 2rem; } }

		.toast-button {
			position: absolute;
			right: 0;
			top: 0;
			border: none;
			min-width: 0;
			background: none;
			color: inherit;
			background-color: transparent;
			&:after {
				display: inline-block;
				content: "\00d7";
				font-size: 190%;
				line-height: 100%; } } } }


// "toast" notifications:
.toast-enter-active,
.toast-leave-active {
	transition: all .5s ease; }
.toast-enter,
.toast-leave-to {
	height: 0; }
.toast-enter-to,
.toast-leave {
	height: 3rem; }
