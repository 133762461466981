table.awards {
	tr.has-feature {
		td {
			border-bottom: none; } }
	tr.award-feature {
		font-style: italic;

		td:last-child {
			text-align: left;
			padding-top: 0; } } }
