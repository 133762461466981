.hero {
	position: relative;
	width: 100%;
	z-index: 1;
	padding: 1rem 1rem 0;
	@include susy-breakpoint($shmedium) {
		padding: 2rem; }

	.hero-wrap {
		border: 2px solid $lightbrown; }

	.slide {
		background-position: center;
		background-size: cover;
		position: relative;
		z-index: 1;
		display: none;

		&:first-child {
			display: block; } }

	.slick-initialized {
		.slide {
			display: block; } } }

.banners {

	.banners-wrap {
		margin: 1rem 0 0;
		@include susy-breakpoint($shmedium) {
			margin: 0 1rem 1rem;
			@apply .flex {} } }

	.banner {
		@apply .flex .items-end {}
		border: 2px solid $lightbrown;
		margin: 0 1rem 1rem;
		background-size: cover;
		background-position: center;
		@include susy-breakpoint($medium) {
			flex-basis: 33.3333%; }

		&::after {
			content: "";
			display: block;
			padding-bottom: 80%;

			@include susy-breakpoint($shmedium) {
				padding-bottom: 100%; } } }

	.banner-title {
		width: 100%;
		text-align: center;
		@apply .font-praktika .uppercase {}

		span {
			display: block;
			padding: 0.5rem 1rem;
			background: $black;
			color: white; } } }


.home-shop {
	text-align: center;
	padding: 2rem 3rem;
	@include susy-breakpoint($medium) {
		padding: 4rem; } }

.home-food {
	text-align: center;

	.food-container {
		.top-left {
			@include span(1 of 1 $nogutter);
			min-height: 400px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			@include susy-breakpoint(900px) {
				@include span(1 of 2 $nogutter); }
			@include susy-breakpoint($large) {
				min-height: 600px; }

			&:hover {
				.content-box {
					opacity: 1; } }

			.content-box {
				position: absolute;
				opacity: 0;
				transition: all 0.3s ease;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				background: url('../img/marble_bg.jpg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				padding: 0 4rem; }

			.content {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center; }

			h4 {
				margin-bottom: 2.5rem; }

			p.title {
				margin: 0; }

			.arrow {
				margin-top: 2.5rem; } }

		.top-right {
			@include span(1 of 1 $nogutter);
			min-height: 400px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			@include susy-breakpoint(900px) {
				@include span(1 of 2 $nogutter); }
			@include susy-breakpoint($large) {
				min-height: 600px; }

			.left {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				@include span(1 of 1 $nogutter);
				@include susy-breakpoint($medium) {
					@include span(1 of 2 $nogutter); }

				&::after {
					content: "";
					position: absolute;
					top: 1rem;
					left: 1rem;
					right: 1rem;
					bottom: 1rem;
					border: 3px solid $brown; }

				&.story {
					background: $blue;
					color: $white; }

				img.flags {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 4rem;
					transform: translate(-50%,-50%);
					transition: all 0.3s ease; }

				.content-box {
					display: flex;
					align-items: center;
					flex-direction: column;
					padding: 0 3rem;
					opacity: 0;
					transition: all 0.3s ease; }

				.arrow {
					margin-top: 2rem;
					rect,
					polygon {
						fill: $white; } }

				&:hover {
					img.flags {
						opacity: 0; }
					.content-box {
						opacity: 1; }
					.arrow {
						animation: arrow 0.5s ease alternate; } } }

			.right {
				display: none;
				@include susy-breakpoint($medium) {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					@include span(1 of 2 $nogutter); }

				.right-top {
					position: absolute;
					height: 50%;
					top: 0;
					right: 0;
					left: 0;
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat; }

				.right-bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 50%;
					background-position: center;
					background-color: $brown;
					background-size: contain;
					background-repeat: no-repeat; } } }

		.bottom-left {
			@include span(1 of 1 $nogutter);
			min-height: 400px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			@include susy-breakpoint(900px) {
				@include span(1 of 2 $nogutter); }
			@include susy-breakpoint($large) {
				min-height: 600px; }

			.left {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				@include span(1 of 2 $nogutter);

				.left-top {
					background: $black;
					position: absolute;
					height: 50%;
					top: 0;
					right: 0;
					left: 0;
					background-position: center;
					background-size: 50%;
					background-repeat: no-repeat; }

				.left-bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 50%;
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat; } }

			.right {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				@include span(1 of 2 $nogutter); } }

		.bottom-right {
			@include span(1 of 1 $nogutter);
			min-height: 400px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			@include susy-breakpoint(900px) {
				@include span(1 of 2 $nogutter); }
			@include susy-breakpoint($large) {
				min-height: 600px; } } } }
