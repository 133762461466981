footer {
	padding: 2rem 1rem;
	text-align: center;
	@include susy-breakpoint($medium) {
		padding: 4rem;
		position: relative; }
	background: $brown;

	@include susy-breakpoint($shmedium) {
		text-align: left; }

	.logo {
		max-width: 180px;
		margin: 0 auto 2rem;
		@include susy-breakpoint($shmedium) {
			max-width: 200px;
			margin: 0 auto 4rem; } }

	.container {
		color: $darkerbrown;

		@include susy-breakpoint($shmedium) {
			@apply .flex .justify-center {} } }

	.footer-col {
		flex: 1 1 auto;

		&:last-child {
			flex: 0; }

		&.flex25 {
			flex-basis: 25%; }

		&.flex50 {
			flex-basis: 50%; } }


	p {
		margin-bottom: 1rem; }

	ul.footer-nav {
		display: none;
		@include susy-breakpoint($shmedium) {
			display: block;
			columns: 2;
			li {
				break-inside: avoid; } } }

	.copyright-info {
		@include susy-breakpoint($shmedium) {
			@apply .flex .items-center {}

			ul {
				@apply .flex {}

				li {
					padding: 0 0 0 2rem; } } } }

	ul.socials,
	ul.cards {
		display: flex;
		justify-content: center;

		li {
			font-size: 1.4rem;
			padding: 0 0.5rem 0;
			@include susy-breakpoint($shmedium) {
				padding: 0 1rem 0 0; } } }

	ul.cards {
		margin-top: 0.5rem;
		@include susy-breakpoint($shmedium) {
			margin-top: 0; }
		li {
			font-size: 1.7rem; } }

	.sub-footer {
		font-size: 0.9rem;

		p {
			margin: 0; } }


	hr {
		border-color: darken($brown, 5%);
		width: 100%; } }
